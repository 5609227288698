import { LogoFooterSatoshity } from 'assets/icons/LogoFooterSatoshity';

const LogoLoading = () => (
  <LogoFooterSatoshity
    disabledLink
    style={{
      width: 150,
      height: 'fit-content',
    }}
  />
);
export { LogoLoading };
